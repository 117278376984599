import axios from '@/utils/axios'
import { BASE_URL } from '@/utils/common'
import Qs from 'qs'

export function getBanner(data) {
    return axios({
        url: `${BASE_URL}banner/list?${Qs.stringify(data)}`,
        method: 'get',
    })
}

export function getSchoolIcon(data) {
    return axios({
        url: `${BASE_URL}school-icon/page?${Qs.stringify(data)}`,
        method: 'get',
    })
}

export function getNews(data) {
    return axios({
        url: `${BASE_URL}news/page?${Qs.stringify(data)}`,
        method: 'get',
    })
}

export function getNewDetail(data) {
    return axios({
        url: `${BASE_URL}news/${data.id}`,
        method: 'get',
    })
}

export function getJobType(data) {
    return axios({
        url: `${BASE_URL}job/category/list?${Qs.stringify(data)}`,
        method: 'get',
    })
}

export function getJobList(data) {
    return axios({
        url: `${BASE_URL}job/page?${Qs.stringify(data)}`,
        method: 'get',
    })
}

export function getJobDetail(data) {
    return axios({
        url: `${BASE_URL}job/${data.id}`,
        method: 'get',
    })
}

export function getRichText() {
    return axios({
        url: `${BASE_URL}config/rich-text`,
        method: 'get',
    })
}

export function postConnect(data) {
    return axios({
        url: `${BASE_URL}collect-information`,
        method: 'post',
        data:Qs.stringify(data)
    })
}
