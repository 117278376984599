
import {defineComponent, nextTick, onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import {getJobList, getJobType, getRichText} from "@/api/webIndex";

export default defineComponent({
  setup() {
    const router = useRouter();

    interface typeItem {
      id: number,
      name: string
    }

    interface jobItem {
      id: number,
      name: string,
      type: any,
      flow: string,
    }

    const
        typeList = ref<typeItem[]>(),
        jobList = ref<jobItem[]>([]),
        typeIndex = ref<number>(0),
        jobWrap = ref<any>(),
        lineHeight = ref<number>(0),
        keyword = ref<string>(''),
        bgUrl = ref<string>('')

    const
        onGetJobList = async (item: any, index: number, keyword?: any) => {
          typeIndex.value = index;
          try {
            let params = {}
            item.id && (params = {...params, category_id: item.id})
            keyword && (params = {...params, keyword})
            let res = await getJobList(params)
            if (res.message === 'ok') {
              jobList.value = res.data.data
              await nextTick(() => {
                lineHeight.value = jobWrap.value.clientHeight;
              })
            }
          } catch (e) {
            console.log(e)
          }
        },
        onToDetail = (id: number) => {
          router.push({
            name: 'joinDetail',
            query: {
              id
            }
          });
        },
        onGetTypeList = async () => {
          try {
            let res = await getJobType()
            if (res.message === 'ok') {
              typeList.value = res.data
              if (res.data.length > 0) {
                await onGetJobList(res.data[0], 0)
              }
            }
          } catch (e) {
            console.log(e)
          }
        },
        onSearchJob = () => {
          keyword.value ? onGetJobList({id: 0}, 0, keyword.value) :
              onGetJobList(typeList.value && typeList.value[0], 0, '')
        },
        onGetVideo = async () => {
          try {
            let res = await getRichText()
            if (res.message === 'ok') {
              bgUrl.value = res.data.join_us_photo
            }
          } catch (e) {
            console.log(e)
          }
        }

    onMounted(() => {
      onGetTypeList()
      onGetVideo()
    });

    return {
      typeList,
      jobList,
      typeIndex,
      jobWrap,
      lineHeight,
      keyword,
      bgUrl,
      onGetJobList,
      onToDetail,
      onGetTypeList,
      onSearchJob
    };
  }
});
